document.addEventListener("DOMContentLoaded", () => {
  const btnMenu = document.querySelector("#btn-menu");
  const btnClose = document.querySelector("#btn-close");
  const menu = document.querySelector("#menu");

  btnMenu.addEventListener("click", () => {
    btnMenu.classList.add("hidden");
    btnClose.classList.remove("hidden");
    menu.classList.remove("hidden");
  });

  menu.addEventListener("click", () => {
    btnMenu.classList.remove("hidden");
    btnClose.classList.add("hidden");
    menu.classList.add("hidden");
  });

  btnClose.addEventListener("click", () => {
    btnMenu.classList.remove("hidden");
    btnClose.classList.add("hidden");
    menu.classList.add("hidden");
  });
});
